import React from "react"
import Layout from "../../components/Layout"
import Post from "../../components/Post/Post"
import H1 from "../../components/H1/H1"
import Pagination from "../../components/Pagination"
import SEO from "../../components/SEO"
import styled from 'styled-components'

const Plus = styled.div`
 
  
`;

function handleClick() {
  let elems = document.getElementsByClassName("displayClick");
  let i;
  if(elems.length > 0) {
    for (i = 0; i < elems.length; i++) {
      elems[i].classList.remove('d-none');
      elems[i].classList.remove('displayClick');
      if(i == 1) {
        return;
      }
    }
  } else {
    document.getElementById('morePosts').classList.remove('d-flex');
    document.getElementById('morePosts').classList.add('d-none');
  }

}


const CategoryArchive = props => {
  const {
    pageContext: { name, posts },
  } = props
  const hiddenHeader = false;
  return (
    <Layout hiddenHeader={hiddenHeader} >
      <SEO
        title="Blog"
        description="Blog posts"
        keywords={[`blog`]}
      />
      <section className="container-fluid px-3 px-md-5 pt-0 pb-0">
        <div className="row justify-content-center">
          <H1 textCenter>{name}</H1>
        </div>
        <div className="row text-center pt-5">
          {posts.nodes && posts.nodes.map((post, index) => <Post key={post.id} post={post} i={index} index={(index%2+1)*300} margin="pb-5" col="col-12 col-md-6" />)}
        </div>
        <Plus id="morePosts" className="d-flex d-md-none justify-content-center mb-2 ">
          <svg width="74px" height="73px" viewBox="0 0 74 73" version="1.1" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="morePost" transform="translate(-151.000000, -1455.000000)" stroke="#8FB0DE">
                <path d="M188,1527 C207.882251,1527 224,1511.10611 224,1491.5 C224,1471.89389 207.882251,1456 188,1456 C168.117749,1456 152,1471.89389 152,1491.5 C152,1511.10611 168.117749,1527 188,1527 Z M203,1492.5 L172,1492.5 M187.5,1476.5 L187.5,1507.5" id="Combined-Shape"></path>
              </g>
            </g>
          </svg>
        </Plus>
      </section>
    </Layout>
  )
}

export default CategoryArchive
